import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { AppBar, Box, useMediaQuery } from "@mui/material";
import { setMenu } from "../store/reducers/app";
import Header from "./Header/Header";

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMenu({ opened: !matchDownMd }));
  }, [matchDownMd, dispatch]);

  return (
    <Box
      sx={{
        bgcolor: "#010C1B",
        position: "relative",
        display: {
          md: "flex"
        },
        justifyContent: {
          md: "center"
        }
      }}
    >
      <Box maxWidth={1440} style={{ position: "relative" }}>
        {/*<CssBaseline />*/}
        {/*/!* header *!/*/}
        <AppBar
          position="fixed"
          color="transparent"
          elevation={0}
          sx={{
            alignItems: "center",
            width: {
              xs: "100%"
            }
          }}
        >
          <Header />
        </AppBar>

        {/*/!* drawer *!/*/}
        {/*<Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />*/}

        {/* main content */}
        {/*<Main theme={theme} open={leftDrawerOpened}>*/}
        <Outlet />
        {/*</Main>*/}
      </Box>
    </Box>
  );
};

export default MainLayout;
