import React, { useCallback } from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import logo from "../../assets/images/logo.svg";
import { styled } from "@mui/system";
import { CommunityData } from "./constant";

export const Info: React.FC = () => {
  const handleScrollTo = useCallback((id) => {
    const element = document?.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
      });
    }
  }, []);

  return (
    <Container
      sx={{
        height: {
          md: "680px"
        }
      }}
    >
      <Box
        display={"flex"}
        flex={2}
        flexDirection={"column"}
        alignItems="center"
        sx={{
          marginLeft: 5,
          marginRight: 5,
          paddingTop: {
            xs: "100px"
          }
        }}
      >
        <Typography variant="subtitle1" color="white" align="center">
          Join our community
        </Typography>
        <Typography color={"white"} variant={"h4"} mt={1} align="center">
          Connect with us community to update the newest thing about the market and our bussiness
        </Typography>
        <Box display="flex" flexDirection="row" mt={2} mb={10}>
          {CommunityData.map((item) => {
            return (
              <BoxContainer key={item.icon}>
                <img
                  src={require(`../../assets/images/community/${item.icon}`)}
                  alt={item.icon}
                  style={{ width: 50, height: 50 }}
                />
              </BoxContainer>
            );
          })}
        </Box>
      </Box>
      <Box
        display={"flex"}
        flex={1}
        flexDirection={"column"}
        sx={{
          paddingLeft: {
            md: "150px",
            xs: "20px"
          },
          paddingRight: {
            md: "150px",
            xs: "20px"
          },
          paddingTop: "40px"
        }}
      >
        <Grid
          container
          spacing={{
            md: 5,
            xs: 2
          }}
        >
          <Grid item md={3} xs={12}>
            <Box flexDirection="row" display="flex" alignItems="center">
              <Avatar variant="rounded" src={logo} sx={{ width: 40, height: 40, mr: 2 }} />
              <Typography color="white">AIC - AI Chat</Typography>
            </Box>
            <Typography
              color="gray"
              mt={4}
              onClick={() => handleScrollTo("roadmap")}
              style={{ cursor: "pointer" }}
            >
              Roadmap
            </Typography>
            <Typography
              color="gray"
              mt={1}
              onClick={() => handleScrollTo("ourteam")}
              style={{ cursor: "pointer" }}
            >
              Ourteam
            </Typography>
            <Typography
              color="gray"
              mt={1}
              onClick={() => handleScrollTo("partner_communities")}
              style={{ cursor: "pointer" }}
            >
              Partners & Communities
            </Typography>
            <Typography color="gray" mt={1}>
              Join our community
            </Typography>
            <Typography color="gray" mt={1}>
              2023 Copyright AIC
            </Typography>
          </Grid>
          <Grid item md={2} xs={12}>
            <Typography color="gray" mt={1}>
              Privacy policy
            </Typography>
            <Typography color="gray" mt={1}>
              Term & Condition
            </Typography>
          </Grid>
          <Grid item md={5} xs={12}>
            <Typography color="gray" mt={1}>
              UEN: 0xaD95349949C99725D735E21C32639ABd81762d80
            </Typography>
            <Typography color="gray" mt={1}>
              Buy us coffee: --
            </Typography>
            <Typography color="gray" mt={1}>
              hi@aic.community
            </Typography>
          </Grid>
          <Grid item md={2} xs={12}>
            <QRCode src={require("../../assets/images/community/qr-code.jpg")} alt={"qr-code"} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

const Container = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column"
});

const BoxContainer = styled("div")({
  width: 50,
  height: 50,
  backgroundColor: "#16294633",
  marginRight: 5
});

const QRCode = styled("img")({
  width: 184,
  height: 184,
  borderRadius: 16
});
