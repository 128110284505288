import React from "react";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { GridProps } from "@mui/material/Grid/Grid";

const Wrapper = styled("div")(() => ({
  backgroundColor: "#010C1B",
  width: "100% "
}));

const WrapperTransparent = styled("div")({});

interface IProps extends GridProps {
  children?: React.ReactNode;
  full?: boolean;
  transparent?: boolean;
}

export const PageContainer: React.FC<IProps> = ({ children, transparent = false, full = false, ...rest }) => {
  const Container = transparent ? WrapperTransparent : Wrapper;
  return (
    <Container>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        {...rest}
        sx={{ minHeight: full ? "100vh" : "20vh" }}
      >
        {children}
      </Grid>
    </Container>
  );
};
