import { takeLatest, put } from "redux-saga/effects";
import { SagaGenerator } from "./types";
import { userActions } from "../reducers/user";
import { toast } from "react-toastify";
import { setLoadingVisible } from "../reducers/app";

export function* userLoginSaga(_action): SagaGenerator {
  try {
  } catch (e) {
    console.log(e);
    toast.error(e.message);
  } finally {
    yield put(setLoadingVisible(false));
  }
}

export function* userLogoutSaga(): SagaGenerator {
  try {
    localStorage.removeItem("token");
  } catch (e) {}
}

export default [
  takeLatest(userActions.userLogin.type, userLoginSaga),
  takeLatest(userActions.userLogout.type, userLogoutSaga)
];
