import { Box, styled } from "@mui/system";
import React from "react";

interface IProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  id?: string;
}

export const ContentContainer: React.FC<IProps> = ({ id, children, style }) => {
  return (
    <Container
      id={id}
      sx={{
        height: {
          md: "1024px",
          xs: "auto"
        },
        paddingTop: {
          xs: "100px"
        }
      }}
      style={style}
    >
      {children}
    </Container>
  );
};

const Container = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover"
});
