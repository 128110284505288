import React from "react";
import { createTheme, ThemeOptions } from "@mui/material/styles";
import colors from "../assets/scss/_themes-vars.module.scss";
import componentStyleOverrides from "./compStyleOverride";
import themePalette from "./palette";
import themeTypography from "./typography";

export * from "./colors";

interface CustomVariants {
  mainContent: React.CSSProperties;
  largeAvatar: React.CSSProperties;
  commonAvatar: React.CSSProperties;
  mediumAvatar: React.CSSProperties;
  menuCaption: React.CSSProperties;
  subMenuCaption: React.CSSProperties;
}

declare module "@mui/material/styles" {
  interface TypographyVariants extends CustomVariants {} // eslint-disable-line

  interface TypographyVariantsOptions extends CustomVariants {} // eslint-disable-line

  interface PaletteVariants {
    primary: React.CSSProperties;
  }

  interface PaletteVariantsOptions extends PaletteVariants {} // eslint-disable-line
}

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    header: typeof colors;
    backgroundWhite: typeof colors;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    mainContent: true;
  }

  interface TypographyPropsVariantOverrides {
    commonAvatar: true;
  }

  interface TypographyPropsVariantOverrides {
    mediumAvatar: true;
  }

  interface TypographyPropsVariantOverrides {
    largeAvatar: true;
  }

  interface TypographyPropsVariantOverrides {
    menuCaption: true;
  }

  interface TypographyPropsVariantOverrides {
    subMenuCaption: true;
  }
}

export const theme = () => {
  const color = colors;

  const themeOption = {
    colors: color,
    heading: color.darkTextTitle,
    paper: color.paper,
    backgroundDefault: color.backgroundWhite,
    darkTextPrimary: color.darkTextPrimary,
    darkTextSecondary: color.darkTextTitle,
    textDark: color.grey900,
    menuSelected: color.secondaryLight,
    menuSelectedBack: color.secondaryDark,
    divider: color.grey200,
    header: color.header
  };

  const themeOptions: ThemeOptions = {
    direction: "ltr",
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px"
        }
      }
    },
    typography: themeTypography(themeOption)
  };
  const themes = createTheme(themeOptions);
  themes.components = componentStyleOverrides(themeOption);

  return themes;
};

export default theme;
