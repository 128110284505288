export const DefaultFilter = {
  limit: 10,
  page: 0,
  filter: {},
  sort: {},
  filterByColumn: false
};

export const ROAD_MAPS = {
  colOne: [
    {
      title: "START",
      description: "Start project and start donate",
      date: "FEB 2023"
    },
    {
      title: "FUNDRAISING",
      description: "Start fundraising such as including Crowdfunding, individual investors, or VCs.",
      date: "MAY 2024"
    },
    {
      title: "LAUNCH AIC APP",
      description: "Upload the data collection app to the AI datasheet",
      date: "JUN 2024"
    },
    {
      title: "DEVELOP CRAWL DATA ENGINE",
      description: "Automatic data collection and sorter on the internet",
      date: "JUL 2024"
    },
    {
      title: "DEVELOP AI TOKEN",
      description: "Develop private chain blockchain core technology",
      date: "SEP 2024"
    },
    {
      title: "COMPLETE DEVELOP CRAWL DATA ENGINE",
      description: "Complete develop Crawl Data Engine",
      date: "JAN 2025"
    }
  ],
  colTwo: [
    {
      title: "PROVIDE AIC DEVICE",
      description: "Support AI training and token collection",
      date: "FEB 2026"
    },
    {
      title: "ISSUE AI TOKEN",
      description: "Share tokens with some users",
      date: "MAY 2025"
    },
    {
      title: "TRAINING APP",
      description: "Launch an app to help the community support AI training  and collect AIC tokens",
      date: "APR 2025"
    },
    {
      title: "SETUP PRIVATE AI TOKEN",
      description: "Setup a private network chain",
      date: "MAR 2025"
    },
    {
      title: "TRAINING AI MODEL",
      description: "Setup training AI based on data collected from users",
      date: "FEB 2025"
    }
  ],
  colThree: [
    {
      title: "GO LIVE OPEN AI FORM",
      description: "Provide AI to community limited by predefined area",
      date: "SEP 2026"
    },
    {
      title: "GO GLOBAL",
      description: "Providing AI to the global community",
      date: "DEC 2026"
    },
    {
      title: "PUBLIC AI TOKEN",
      description: "Allow everyone to share the AIC Token",
      date: "FEB 2027"
    },
    {
      title: "FEATURE ADVANCE",
      description: "Advanced features are exchanged with the collection of AIC tokens",
      date: "MAY 2027"
    },
    {
      title: "AIC TOKEN ON THE EXCHANGE",
      description: "Allows wide exchange of exchanges",
      date: "DEC 2027"
    }
  ]
};
