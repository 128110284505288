import React, { useEffect } from "react";
import "./App.css";
import themes from "./themes";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { AuthContainer, Loader, Loading } from "./components";
import NavigationScroll from "./layout/NavigationScrool";
import { ToastContainer } from "react-toastify";
import { Routes, Route } from "react-router-dom";
import { MainRoutes, PrivateRoute } from "./routes";

function App() {
  useEffect(() => {}, []);

  return (
    <React.Suspense fallback={<Loader />}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes()}>
          <CssBaseline />
          <AuthContainer>
            <NavigationScroll>
              <Routes>
                <Route path={"/"} element={<PrivateRoute />}>
                  {MainRoutes.map((item) => {
                    return <Route key={item.path} {...item} />;
                  })}
                </Route>
              </Routes>
            </NavigationScroll>
          </AuthContainer>
        </ThemeProvider>
      </StyledEngineProvider>
      <ToastContainer />
      <Loading />
    </React.Suspense>
  );
}

export default App;
