import React, { useCallback, useMemo } from "react";
import { Box, styled } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import { ContentContainer } from "../../components";
import backgroundImage from "../../assets/images/background.svg";
import { ROAD_MAPS } from "../../constant";
import useIsMobile from "../../hooks/hooks";

interface ILineItem {
  hideTop?: boolean;
  roundBottomItem?: boolean;
  hideLine?: boolean;
  roundTopItem?: boolean;
}

export const RoadMap: React.FC = () => {
  const isMobile = useIsMobile();

  const roadMaps = useMemo(() => {
    return [...ROAD_MAPS.colOne, ...[...ROAD_MAPS.colTwo].reverse(), ...ROAD_MAPS.colThree];
  }, []);

  const renderDesktopRoadMap = useCallback(() => {
    return (
      <Grid container mt={10} xs={10}>
        <Grid item xs={4} pt={6} display="flex" justifyContent="space-between" flexDirection="column">
          {ROAD_MAPS.colOne.map((item, index) => {
            const roundBottomItem = index === ROAD_MAPS.colOne.length - 1;
            const hideLine = index === 0;
            return (
              <RoadMapItem key={index.toString()} roundBottomItem={roundBottomItem}>
                <Box display="flex" flexDirection="row">
                  <Dot />
                  <Typography color={"white"} variant="body1">
                    {item.date}
                  </Typography>
                </Box>
                <Content>
                  <Typography color={"white"} variant="body1">
                    {item.title}
                  </Typography>
                  <Typography color={"white"} variant="body1">
                    {item.description}
                  </Typography>
                </Content>
                <LineItem roundBottomItem={roundBottomItem} hideLine={hideLine} hideTop={true} />
              </RoadMapItem>
            );
          })}
        </Grid>
        <Grid
          item
          xs={4}
          pt={6}
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          position="relative"
        >
          {ROAD_MAPS.colTwo.map((item, index) => {
            const hideLine = index === ROAD_MAPS.colTwo.length - 1;
            const roundTopItem = index === 0;
            return (
              <RoadMapItem key={index.toString()}>
                <Box display="flex" flexDirection="row">
                  <Dot />
                  <Typography color={"white"} variant="body1">
                    {item.date}
                  </Typography>
                </Box>
                <Content>
                  <Typography color={"white"} variant="body1">
                    {item.title}
                  </Typography>
                  <Typography color={"white"} variant="body1">
                    {item.description}
                  </Typography>
                </Content>
                <LineItem hideLine={hideLine} roundTopItem={roundTopItem} />
              </RoadMapItem>
            );
          })}
        </Grid>
        <Grid
          item
          xs={4}
          pt={6}
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          position="relative"
        >
          {ROAD_MAPS.colThree.map((item, index) => {
            const hideLine = index === 0;
            return (
              <RoadMapItem key={index.toString()}>
                <Box display="flex" flexDirection="row">
                  <Dot />
                  <Typography color={"white"} variant="body1">
                    {item.date}
                  </Typography>
                </Box>
                <Content>
                  <Typography color={"white"} variant="body1">
                    {item.title}
                  </Typography>
                  <Typography color={"white"} variant="body1">
                    {item.description}
                  </Typography>
                </Content>
                <LineItem hideLine={hideLine} hideTop={true} />
              </RoadMapItem>
            );
          })}
          <Triangle />
        </Grid>
      </Grid>
    );
  }, []);

  const renderMobileRoadMap = useCallback(() => {
    return (
      <Grid container mt={10} xs={10}>
        <Grid
          item
          pt={6}
          position="relative"
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          {roadMaps?.map((item, index) => {
            const hideTopLine = index === 0;
            const paddingBottom = index === roadMaps.length - 1;
            return (
              <RoadMapItem key={index.toString()} roundBottomItem={paddingBottom}>
                <Box display="flex" flexDirection="row">
                  <Dot />
                  <Typography color={"white"} variant="body1">
                    {item.date}
                  </Typography>
                </Box>
                <Content>
                  <Typography color={"white"} variant="body1">
                    {item.title}
                  </Typography>
                  <Typography color={"white"} variant="body1">
                    {item.description}
                  </Typography>
                </Content>
                <LineItem hideTop={hideTopLine} hideLine={hideTopLine} />
              </RoadMapItem>
            );
          })}
          <Triangle />
        </Grid>
      </Grid>
    );
  }, [roadMaps]);

  return (
    <ContentContainer style={{ backgroundImage: `url(${backgroundImage})` }} id="roadmap">
      <Typography color={"white"} variant="subtitle1">
        ROADMAP
      </Typography>
      {isMobile ? renderMobileRoadMap() : renderDesktopRoadMap()}
    </ContentContainer>
  );
};

const RoadMapItem = styled(Box)<ILineItem>((props) => {
  const { roundBottomItem } = props;
  return {
    position: "relative",
    flex: 1,
    paddingTop: 50,
    paddingBottom: roundBottomItem ? 40 : 0,
    paddingRight: 10
  };
});

const LineItem = styled("div")<ILineItem>((props) => {
  const { roundBottomItem, hideLine, roundTopItem, hideTop } = props;
  return {
    borderLeft: "1px solid #ffffff",
    position: "absolute",
    width: roundBottomItem || roundTopItem ? "calc(100% + 1px)" : "100%",
    height: hideLine ? "50%" : "100%",
    top: hideTop ? "" : 0,
    bottom: hideTop ? 0 : "",
    borderBottom: roundBottomItem ? "1px solid #ffffff" : " ",
    borderRight: roundBottomItem || roundTopItem ? "1px solid #ffffff" : "",
    borderBottomLeftRadius: roundBottomItem ? 35 : 0,
    borderBottomRightRadius: roundBottomItem ? 35 : 0,
    borderTop: roundTopItem ? "1px solid #ffffff" : " ",
    borderTopLeftRadius: roundTopItem ? 35 : 0,
    borderTopRightRadius: roundTopItem ? 35 : 0
  };
});

const Content = styled(Box)({
  marginLeft: 30
});

const Dot = styled("div")({
  width: 20,
  height: 20,
  borderRadius: 20,
  backgroundColor: "#24EAF4",
  marginLeft: -9,
  marginRight: 20,
  zIndex: 999
});

const Triangle = styled("div")({
  width: 0,
  height: 0,
  position: "absolute",
  borderLeft: "10px solid transparent",
  borderRight: "10px solid transparent",
  borderTop: "15px solid #ffffff",
  bottom: 0,
  zIndex: 99,
  left: -10
});
