import React from "react";
import { Dashboard } from "../views";
// ==============================|| MAIN ROUTING ||============================== //

export const RouteKey = {
  default: "/",
  welcome: "/welcome"
};

export const MainRoutes = [
  {
    path: RouteKey.default,
    element: <Dashboard />,
    name: "Welcome",
    key: "welcome"
  }
];

export const checkPathInRoutes = (path: string): boolean => {
  return !!MainRoutes.find((item) => item.path.includes(path));
};
