import React from "react";
import { styled } from "@mui/system";
import animationData from "../assets/animation/hello.json";
import Lottie from "react-lottie";

const Container = styled("div")({
  height: "100vh",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#010C1B"
});

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

export const SplashScreen: React.FC = () => {
  return (
    <Container>
      <Lottie options={defaultOptions} height={"100%"} width={"100%"} />
    </Container>
  );
};
