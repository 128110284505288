import { applyMiddleware } from "redux";
import reducers from "./reducers";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";

export * from "./hooks";
export * from "./selectors";

// ==============================|| REDUX - MAIN STORE ||============================== //

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["app"]
};

const persistedReducer = persistReducer(persistConfig, reducers);

const sagaMiddleware = createSagaMiddleware();

const middlewareEnhancer = applyMiddleware(sagaMiddleware);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: false
    }),
  enhancers: [middlewareEnhancer]
});

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export type StoreState = ReturnType<typeof store.getState>;

export type StoreDispatch = typeof store.dispatch;

export { store, persistor };
