import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { getAppLoadingIndicator, useStoreSelector } from "../store";
import { styled } from "@mui/system";

const LoadingContainer = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "transparent",
  display: "flex"
});

export const Loading: React.FC = () => {
  const appLoadingIndicator = useStoreSelector(getAppLoadingIndicator);
  if (!appLoadingIndicator) {
    return null;
  }
  return (
    <LoadingContainer>
      <CircularProgress />
    </LoadingContainer>
  );
};
