import logo from "../../assets/images/logo.svg";
import Tony from "../../assets/images/our-team/tony-stark.png";
import Peter from "../../assets/images/our-team/peter-jo.png";
import Alexander from "../../assets/images/our-team/alexander-narow.png";

export const OurTeamProfile = [
  {
    avatar: Tony,
    name: "Tony Stark",
    role: "Technical Director"
  },
  {
    avatar: Peter,
    name: "Peter Jo",
    role: "General Director"
  },
  {
    avatar: Alexander,
    name: "Alexander Narow",
    role: "Desgin Director"
  }
];

export const Partners = [
  {
    avatar: logo,
    name: "Guy Hawkins",
    role: "Medical Assistant"
  },
  {
    avatar: logo,
    name: "Guy Hawkins",
    role: "Medical Assistant"
  },
  {
    avatar: logo,
    name: "Guy Hawkins",
    role: "Medical Assistant"
  }
];

export const CommunityData = [
  {
    icon: "facebook.svg",
    link: ""
  },
  {
    icon: "instagram.svg",
    link: ""
  },
  {
    icon: "linkedin.svg",
    link: ""
  },
  {
    icon: "twitter.svg",
    link: ""
  },
  {
    icon: "youtube.svg",
    link: ""
  }
];
