import { createSlice } from "@reduxjs/toolkit";
import { AppStore } from "../types";

const initialState: AppStore = {
  isOpen: [],
  opened: true,
  appLoadingIndicator: false
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setMenu(state, action) {
      state.opened = action.payload.opened;
    },
    menuOpen(state, action) {
      state.isOpen = [action.payload.id];
    },
    setLoadingVisible(state, action) {
      state.appLoadingIndicator = action.payload;
    }
  }
});

export const { setMenu, menuOpen, setLoadingVisible } = appSlice.actions;

export default appSlice.reducer;
