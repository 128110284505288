import { combineReducers } from "redux";
import user from "./reducers/user";
import app from "./reducers/app";

const reducers = combineReducers({
  user: user,
  app: app
});

export default reducers;
