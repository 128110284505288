import React from "react";
import { Box, styled } from "@mui/system";
import { OurTeamProfile } from "./constant";
import { Avatar, Typography } from "@mui/material";
import { ContentContainer } from "../../components";
import backgroundImage from "./../../assets/images/background.svg";

export const OurTeam: React.FC = () => {
  return (
    <ContentContainer style={{ backgroundImage: `url(${backgroundImage})` }} id="ourteam">
      <Typography color={"white"} variant="subtitle1">
        OURTEAM
      </Typography>
      <Wrapper
        sx={{
          width: {
            md: "75%",
            xs: "90%"
          }
        }}
      >
        {OurTeamProfile.map((item, index) => {
          return (
            <Box key={index.toString()} display={"flex"} alignItems={"center"} flexDirection={"column"}>
              <AvatarContainer>
                <AvatarWrapper>
                  <Avatar
                    variant="rounded"
                    src={item.avatar}
                    sx={{
                      width: {
                        md: 160,
                        xs: 130
                      },
                      height: {
                        md: 160,
                        xs: 130
                      },
                      backgroundColor: "transparent"
                    }}
                  />
                </AvatarWrapper>
              </AvatarContainer>
              <Typography color={"white"} mt={1} variant="h3">
                {item.name}
              </Typography>
              <Typography color={"gray"} mt={1}>
                {item.role}
              </Typography>
            </Box>
          );
        })}
      </Wrapper>
    </ContentContainer>
  );
};

const Wrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: 60,
  position: "relative",
  flexWrap: "wrap"
});

const AvatarContainer = styled(Box)({
  borderRadius: 100,
  border: "1px solid #24EAF4",
  overflow: "hidden",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  padding: 8,
  marginTop: 20
});

const AvatarWrapper = styled("div")({
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  borderRadius: 100,
  overflow: "hidden",
  alignItems: "center",
  justifyContent: "center",
  display: "flex"
});
