import { createSlice } from "@reduxjs/toolkit";
import { UserStore } from "../types";

export const initialState: UserStore = {
  isLogged: false
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogin: (_, __) => {},
    userLogout: (state) => {
      state.isLogged = false;
    },
    userLoginSuccess: (state, _action) => {
      state.isLogged = true;
    }
  }
});

export const { actions: userActions } = userSlice;

export default userSlice.reducer;
