import React, { useCallback } from "react";
import { styled } from "@mui/material/styles";
import { Box, Tooltip, Typography } from "@mui/material";

import logo from "../../assets/images/logo.svg";
import { toast } from "react-toastify";

const Header: React.FC = () => {
  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText("0xaD95349949C99725D735E21C32639ABd81762d80");
    toast.success("Copy successfully!");
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#010C1B",
        mt: 2,
        border: `1px solid white`,
        borderRadius: 3,
        padding: "16px",
        alignItems: "center",
        width: {
          xs: "90%",
          md: "auto"
        },
        height: {
          xs: "76px"
        },
        overflow: "clip"
      }}
    >
      <Logo src={logo} />
      <Typography color="white" variant={"h2"} mr={2}>
        AIC
      </Typography>
      <Tooltip title="Click to copy!">
        <BlockInfo
          onClick={handleCopy}
          sx={{
            marginRight: "5px"
          }}
        >
          0xaD95349949C99725D735E21C32639ABd81762d80
        </BlockInfo>
      </Tooltip>
      <Tooltip title="Click to copy!">
        <QRCode src={require("../../assets/images/community/qr-code.jpg")} onClick={handleCopy} />
      </Tooltip>
      <BlockInfo
        sx={{
          display: {
            md: "flex",
            xs: "none"
          }
        }}
      >
        AI was born by humans and serves human happiness
      </BlockInfo>
    </Box>
  );
};

const Logo = styled("img")({
  width: 44,
  height: 44,
  marginRight: 8
});

const BlockInfo = styled(Box)({
  border: "1px 0 0 0 solid white",
  color: "white",
  // padding: "0 20px 0 20px",
  fontSize: 16,
  alignItems: "center",
  display: "flex",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  cursor: "pointer"
});

const QRCode = styled("img")({
  width: 60,
  height: 60,
  borderRadius: 4,
  marginLeft: "20px",
  marginRight: "20px",
  cursor: "pointer"
});

export default Header;
