import React from "react";
import { ContentContainer, PageContainer } from "../../components";
import animationData from "../../assets/animation/hero.json";
import Lottie from "react-lottie";
import { RoadMap } from "./road-map";
import { OurTeam } from "./our-team";
import { Partner } from "./partner";
import { Info } from "./info";
import backgroundImage from "../../assets/images/main-background.svg";
import { Box } from "@mui/system";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

export const Dashboard: React.FC = () => {
  return (
    <PageContainer full>
      <ContentContainer style={{ backgroundImage: `url(${backgroundImage})`, paddingTop: 100 }}>
        <Box
          sx={{
            width: {
              md: "1080px",
              xs: "100%"
            }
          }}
        >
          <Lottie options={defaultOptions} height={"852px"} width={"100%"} />
        </Box>
      </ContentContainer>
      <RoadMap />
      <OurTeam />
      <Partner />
      <Info />
      <div style={{ height: 40 }} />
    </PageContainer>
  );
};
