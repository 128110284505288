import React, { useEffect, useState } from "react";
import { useStoreDispatch } from "../store";
import { SplashScreen } from "./SplashScreen";

export const AuthContainer: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const dispatch = useStoreDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 6000);
  }, [dispatch]);

  if (loading) {
    return <SplashScreen />;
  }
  return children;
};
